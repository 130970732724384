<template>
  <div class="kecheng">
    <kind-choose-view :showKc="true" :type="12" @isOk="isOk" :entrantsType="3" :testPaperType="testPaperType" />

    <!-- 学习资料 -->
    <div class="quesTion-car" v-if="list && list.length > 0">
      <div class="item page" v-for="(item, index) in list" :key="index">
        <img class="item-img examPage" src="@/assets/img/homeSeventh/dataIcon.png" />
        <div class="item-content">
          <div class="item-title twoEllipsis">{{ item.title }}</div>
          <div class="item-tips elp">
            共{{ item.number ? item.number : 0 }}份资料
          </div>
          <div class="item-but">
            <div class="colleges-price">
              <span class="price-icon">￥</span>{{ item.price }}
            </div>
            <div v-if="(item.price > 0 && item.isPurchase == 1) || !userInfo">
              <!-- <div
              class="colleges-learn keep-check"
              @click="goOrder(item)"
            >
              立即购买
            </div> -->
              <div class="colleges-learn" @click="toMaterialPage(item)">
                查看资料
              </div>
            </div>
            <div v-else class="colleges-learn" @click="toMaterialPage(item)">
              立即查看
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无课程 -->
    <div class="nothing" v-if="!list || list.length == 0">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        margin: 40px auto;
        margin-bottom: 80px;
      ">
      <el-pagination class="pagination colleges-pagination" :total="total" :current-page="pageNum"
        :page-sizes="[12, 24, 30, 42]" :page-size="pageSize" :pager-count="5" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";

import KindChooseView from "@/components/kindChooseViewzlSeventh.vue";
import { DataClient } from "@/api/dataClient";
let dataClient = new DataClient();
import { getInfo, getToken } from "@/api/cookies";
import { OrderClient } from "@/api/orderClient";
let orderClient = new OrderClient();

export default {
  components: { KindChooseView },

  data() {
    return {
      list: {},
      search: {},
      pageSize: 12,
      pageNum: 1,
      total: 0,
      userInfo: null,

      testPaperType: null,
    };
  },
  created() {
    this.testPaperType = this.$route.query.type;
    this.userInfo = getInfo();
    // this.getList();
  },
  methods: {
    // 获取知识套餐列表
    getList() {
      dataClient
        .getNewPackageList(
          this.pageSize,
          this.pageNum,
          this.search.kind,
          this.search.stageName,
          this.search.zyId,
          this.search.type,
          this.userInfo ? this.userInfo.id : undefined,
          this.search.kcId
        )
        .then((res) => {
          this.list = res.rows
          this.total = res.total
          if (res.code) {
            this.list = []
            this.total = 0
          }
        })
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    // 筛选
    isOk(val) {

      this.search = val;
      this.getList();
    },
    goOrder(item) {
      let token = getToken();
      if (token) {
        orderClient
          .addOrders(
            item.id,
            localStorage.getItem("userId"),
            item.title,
            5,
            item.title,
            item.kind
          )
          .then((res) => {
            if (res.code == 0) {
              /*   this.users = res.msg; */
              this.$router.push({
                name: "个人中心订单页",
                params: { orderNumber: res.msg },
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        Vue.prototype.goLoginView(true);
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    // 点击图片跳转到详情页
    checkClick(item) {
      if (!this.userInfo) {
        this.$notify({
          title: "错误",
          message: "未登录，无法查看, 请先去登录",
          type: "error",
          duration: 2000,
        });
        return;
      }
      this.$router.push(`/data/dataPackageDetail?packageId=${item.id}`);
    },
    /* 点击免费试看 */
    toMaterialPage(item) {
      if (this.userInfo) {
        this.$router.push({
          path: `/data/materialPage`,
          query: {
            id: item.id,
            title: item.title,
            schoolId: item.schoolId,
            isPurchase: item.isPurchase,
            num: item.price,
          },
        });
      } else {
        this.$notify({
          title: "错误",
          message: "未登录，请去登录",
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
