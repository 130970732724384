import { render, staticRenderFns } from "./dataPackage.vue?vue&type=template&id=20a1d4ec&scoped=true&"
import script from "./dataPackage.vue?vue&type=script&lang=js&"
export * from "./dataPackage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a1d4ec",
  null
  
)

export default component.exports